// import "gantt-task-react/dist/index.css";
import { Popover, Table, Tooltip, Tabs, Button, Alert } from "antd";

import biomarker from "../../assets/images/tableIcons/biomarker.png";
import outcome from "../../assets/images/tableIcons/outcome.png";
import finding from "../../assets/images/tableIcons/finding.png";
import test from "../../assets/images/tableIcons/test.png";
import diagnosis from "../../assets/images/tableIcons/diagnosis.png";
import drug from "../../assets/images/tableIcons/drug.png";
import mutation from "../../assets/images/tableIcons/mutation.png";
import metastasis from "../../assets/images/tableIcons/metastasis.png";
import vitals from "../../assets/images/tableIcons/vitals.png";
import visit from "../../assets/images/tableIcons/visit.png";
import radiotherapy from "../../assets/images/tableIcons/radiotherapy.png";
import othertreatment from "../../assets/images/tableIcons/othertreatment.png";
import adverseevents from "../../assets/images/tableIcons/adverseevents.png";
import surgery from "../../assets/images/tableIcons/surgery.png";

import { useEffect, useState } from "react";
import { CaracteristicsWrapper } from "./style";

const { TabPane } = Tabs;

const monthsNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const icons = {
  biomarker,
  "negative biomarker": biomarker,
  outcome,
  finding,
  test,
  diagnosis,
  "drug treatment": drug,
  mutation,
  "negative mutation": mutation,
  "positive mutation": mutation,
  vitals,
  metastasis,
  "concomitant treatment": drug,
  "drug class": drug,
  "clinical visit": visit,
  radiotherapy,
  "other treatments": othertreatment,
  "adverse event": adverseevents,
  surgery,
  endpoint: outcome,
};

const updateColor = () => {
  setImmediate(() => {
    [...document.querySelectorAll(".text")].forEach((e) => {
      if (e.scrollWidth > e.getBoundingClientRect().width && !e.offsetWidth) {
        e.style.color = "#414BB2";
        e.style.textShadow = "none";
      }
    });
  });
};

const GanttChart = (props) => {
  const [formattedHeader, setformattedHeader] = useState([]);

  const dates = props.data.map((e) => new Date(e.start));
  let minDate = dates.sort((a, b) => a - b)[0];
  let maxDate = dates.sort((a, b) => b - a)[0];
  let months = (maxDate?.getFullYear() - minDate?.getFullYear()) * 12;
  const minYear = +minDate?.getFullYear();
  const startingMonth = minDate?.getMonth();
  const monthsHeader = [];
  months -= minDate?.getMonth();
  months += maxDate?.getMonth();

  useEffect(() => {
    updateColor();

    for (let i = startingMonth; i <= months + startingMonth; i++) {
      monthsHeader.push(monthsNames[Math.floor(i % 12)]);
    }

    if (!monthsHeader.length) monthsHeader.push(monthsNames[startingMonth]);

    const formattedHeaderCells = monthsHeader.map((e, ndx) => {
      return `${e} ${minYear + +Math.floor((ndx + minDate?.getMonth()) / 12)}`;
    });

    setformattedHeader(formattedHeaderCells);
  }, [props.data]);

  const cols = [
    {
      title: "From",
      dataIndex: "start",
      render: (date) => {
        return new Date(date).toLocaleDateString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
      },
      width: 120,
      fixed: "left",
    },
    {
      title: "To",
      dataIndex: "end",
      render: (date) =>
        new Date(date).toLocaleDateString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric",
        }),
      width: 120,
      fixed: "left",
    },
    {
      title: "Type",
      dataIndex: "label",
      width: 200,
      render: (label) => (
        <span>
          <img
            src={icons[label.toLowerCase()]}
            width="25"
            style={{ marginRight: 10 }}
          />{" "}
          {label}
        </span>
      ),
      fixed: "left",
      filters: props.data
        .map((e) => ({ text: e.label, value: e.label }))
        .filter(
          (value, index, self) =>
            index ===
            self.findIndex(
              (t) => t.text === value.text && t.value === value.value
            )
        )
        .sort((a, b) => a.text.localeCompare(b.text)),
      onFilter: (value, record) => {
        return record.label === value;
      },
    },
    {
      title: (
        <div
          style={{
            width: months * 100,
            background:
              "repeating-linear-gradient(90deg, #ffffff 1px, #FFFFFF 48px,  #f1f1f1 49px, #f1f1f1 100px)",
            height: 70,
            marginTop: -18,
            marginBottom: -21,
            marginLeft: -16,
            paddingTop: 13,
          }}
        >
          {formattedHeader.map((e) => (
            <span
              style={{
                width: 49.5,
                display: "inline-block",
                textAlign: "center",
              }}
            >
              {e}
            </span>
          ))}
        </div>
      ),
      dataIndex: "",
      render: (a, b, c) => {
        const details = a.children
          .map((c) => `${c.label}: ${c.text}`)
          .join("\n");
        const currDate = new Date(a.start);
        let offset = (currDate.getFullYear() - minDate.getFullYear()) * 12;
        offset -= minDate.getMonth();
        offset += currDate.getMonth();

        const endDate = new Date(a.end);
        // let size = (endDate.getFullYear() - currDate.getFullYear()) * 12;
        const diffTime = Math.abs(endDate - currDate);
        const size = Math.ceil(diffTime / (1000 * 60 * 60 * 24) / 30) * 50;

        return (
          <Popover
            defaultVisible={false}
            content={<pre>{details}</pre>}
            title="Details"
            trigger="hover"
            placement="topLeft"
            visible={details.length ? undefined : false}
          >
            <div
              style={{
                width: size < 10 ? 10 : size,
                background: "#414BB2",
                height: 25,
                borderRadius: 5,
                padding: "1px 4px",
                color: "#fff",
                marginLeft: offset * 50,
                whiteSpace: "pre",
              }}
            >
              <span
                style={{
                  marginLeft: 10,
                  display: "block",
                  color: "#fff",
                  textShadow: "1px 1px 2px #000",
                }}
                className="text"
              >
                {a.text}
              </span>
            </div>
          </Popover>
        );
      },
      width: months * 100,
    },
  ];

  const getYscroll = () => {
    const vh = Math.max(
      document.documentElement.clientHeight || 0,
      window.innerHeight || 0
    );
    return (vh * 60) / 100;
  };

  const dateFormatter = date => {
    const year = new Date(date)?.getFullYear();
    const month = (new Date(date)?.getMonth() + 1)?.toString().padStart(2, "0");

    return year + "-" + month;
  }

  const downloadCSV = () => {
    const csvHeader = "ID,\t Type,\t Start,\t End,\t Details \n";

    const data = csvHeader + props.data.map(row => {
      const newRow = {
        id: row.id,
        type: `${row.label}: ${row.text}`,
        start: dateFormatter(row.start),
        end: dateFormatter(row.end),
        children: row.children.map((c) => `${c.label}: ${c.text}`).join(" | ")
      }
      return Object.values(newRow).join(",\t");
    }).join("\n");

    const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, "report.csv");
    } else {
      const link = document.createElement("a");
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", "report.csv");
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  return (
    // <Gantt
    //   tasks={data}
    //   viewMode="Month"
    //   // TooltipContent={(e) => (
    //     // <div>{e.task?.children?.map((c) => Object.values(c).join(" "))}</div>
    //   // )}
    //   TaskListHeader={(a,b,c) => {
    //     // console.log({a,b,c})
    //   }}
    // />

    <Tabs defaultActiveKey="1">
      <TabPane tab="Gantt View" key="1">
        {props.error ? (
          <Alert type="error" message={props.error} />
        ) : (
          <Table
            loading={props.loading}
            columns={cols}
            dataSource={props.data || []}
            rowKey={(record) => record.id}
            rowExpandable={false}
            expandable={{
              rowExpandable: false,
              expandIcon: () => <span />,
            }}
            pagination={false}
            rowClassName={(record, index) =>
              index % 2 === 0 ? "table-row-light" : "table-row-dark"
            }
            scroll={{
              y: getYscroll(),
            }}
            onChange={(e) => {
              updateColor();
            }}
            footer={() => null}
          />
        )}
      </TabPane>
      <TabPane tab="Characteristics" key="2">
        <CaracteristicsWrapper>
          <div>
            {Object.entries(props.characteristics || {}).map(([k, v]) => (
              <>
                <b>{k}:</b> {v}
                <span className="seperator" />
              </>
            ))}
          </div>
        </CaracteristicsWrapper>
        <div
          style={{
            clear: "both",
            float: "right",
            marginTop: 50,
          }}
        >
          {/* <Button
            style={{
              marginRight: 20,
            }}
          >
            Download PDF
          </Button> */}
          <Button onClick={downloadCSV}>Download CSV</Button>
        </div>
      </TabPane>
    </Tabs>
  );
};

export default GanttChart;
